@import "../index.scss";
.pages{
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      padding: 5px;
      fill: #333;
      height:18px;
      width:18px;
      cursor: pointer;
    }
    svg:hover{
      fill: $main-blue;
    }
    #rotate{
       transform: rotate(180deg);
    }
    span{
      min-width: 20px;
      min-height: 20px;
      padding:5px;
      margin:5px;
      cursor:pointer;
      background-color: #eee;
      color: #333;
      border-radius:100%;
      transition: 0.1s ease-out;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span:hover{
      color: $main-blue;
    }
    .active{
      background-color: $main-blue;
      color: #fff;
    }
    .active:hover{
      color: #fff;
    }
  }
  .selectedTab{
    border-bottom: $hover-border-size solid $yellow;
    background-color: $main-blue;
    font-weight: bold;
    color: $yellow;
  }