$brand-color: #0071b3;
$text-dark: #252b33;
$eSapce-yellow: #f89413;
$eSpace-light-blue: #59b3d5;
$eSpace-green: #00a15d;
$eSpace-red: #d13f28;
$shadow: #ddd;
$bg : #fff;
$main-blue: #4356D5;
$dark-blue: #2E3EA7;
$light-blue: #D6DDE1;
$yellow: #f5ee9e;
$hover: #4A57B2;
$hover-border-size: 4px;

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
body {
  font-family: "Roboto", sans-serif;
  color: $text-dark;
  background-color: #fafafa;
  margin:0;
  overflow-x: hidden;
}

#nprogress .bar {
	background: #f5ee9e !important;
	height: 5px !important;
}

#nprogress .peg {
	box-shadow: 0 0 10px #f5ee9e, 0 0 5px #f5ee9e !important;
}