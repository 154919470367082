.expired-container{
    text-align: center;
    .expired-text{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        margin: 12px;
        color: #E53935;
        justify-content: center;
        font-size: 2em;
        font-weight: bold;
        img{
            height: 90px;
            width: 90px;
            margin-bottom: 10px;
        }
    }

}