@import "../scss/index.scss";
.msg {
  font-weight: lighter;
  font-size: 12px;
  color: #666;
  padding: 8px;
  border-radius: 20px;
  display: inline-flex;
}

.error {
  background-color: #f7beb5;
  border: 2px solid $eSpace-red;
}
 .success {
   background-color: #adf0d3;
   border: 2px solid $eSpace-green;
 }
