@import "../index.scss";
ul.react-autocomplete-input{
  background-color: #333;
  color: #fff;
}
.react-autocomplete-input {
  color: red;
}

.items-container{
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
    .items-form{
      width:100%;
      margin:0px;
      padding:0px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      span{
        width: 80%;
        input{
          width: 100%;
        }
        .TF-choice{
          width: 100%
        }
      }
      input{
       border:none;
       border-bottom: 3px solid $eSpace-light-blue;
       width:100%;
       padding: 10px;
       margin:10px 0px;
      }
      button{
        border:none;
        background-color: transparent;
      }
        svg{
          width:24px;
          height:24px;
          cursor: pointer;
          padding:0px;
          margin:0px;
          margin-bottom:5px;
          fill: #aaa;
          transition: fill 0.2s ease-out;
        }
        svg:hover{
          fill: $eSpace-light-blue;
        }
  }

  }
  .choices{
    width: 100%;
    .hidden-radio{
      visibility: hidden;
      height: 0px
    }
    .choice-container{
      display: flex;
      align-items: center;
      justify-content: space-between;
      svg{
        fill: #dbccca;
        cursor: pointer;
      }
    }
    .choice-container:hover{
      svg{
        fill: $eSpace-red;
      }
    }
    .choice{
      box-sizing: border-box;
      background-color: #f9f9f9;
      width: 90%;
      border-radius: 10px;
      padding: 5px 10px;
      margin: 10px 0px;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 2px 5px #eee;
      color: #777;
      cursor: pointer;
      transition: box-shadow 0.2s ease-out;
      transition: color 0.2s ease-out;
    }
    .choice-tf{
      box-sizing: border-box;
      background-color: #f9f9f9;
      width: 90%;
      border-radius: 10px;
      padding: 5px 10px;
      margin: 10px 24px;
      min-height: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0px 2px 5px #eee;
      color: #777;
      cursor: pointer;
      transition: box-shadow 0.2s ease-out;
      transition: color 0.2s ease-out;
    }
    .choice:hover, .choice-active{
      box-shadow: 0px 4px 5px #eee;
      color: $text-dark;
    }
    .choice-active {
      border: 2px solid $eSpace-green;
    }
    .choice-active:after {
      content: url("../../images/checkmark.svg");

    }
}
.TF-choice{
  input{
    // visibility: hidden;
    height: 0px;
  }
  label{
    height: 20px
  }
  box-sizing: border-box;
  background-color: #f9f9f9;
  width: 90%;
  border-radius: 10px;
  padding: 5px 10px;
  min-height: 30px;
  box-shadow: 0px 2px 5px #eee;
  color: #777;
  cursor: pointer;

}

.tags {
  display: flex;
  flex-wrap: wrap;
  .tag{
    padding: 5px;
    background-color: $eSpace-light-blue;
    margin: 5px;
    color: #fff;
    border-radius: 5px;
    font-size: 14px;
    svg{
      fill: #fff;
      width:12px;
      height:12px;
      padding: 0px 0px 0px 5px;
      transition: 0.1s ease-out;
    }
    svg:hover{
      fill: $eSpace-red;
    }
  }
}
