@import "../index.scss";

.question{

	background-color: #fff;
	box-shadow: 0px 3px 10px $light-blue;
	max-width:400px;
  min-width:200px;
	grid-row-end: span 2;
	padding:20px;
	border-radius:10px;
	font-family:sans-serif;
	transition:  0.3s ease-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
	header{
    width:100%;
		display:flex;
		justify-content: space-between;
		align-items:center;
		.left{
			display:flex;
			justify-content:center;
			align-items:center;
			i{
				color: #AEADBA;
				font-size: 16px;
				padding: 2px;
			}
			
			span{
				padding-right: 10px;
				font-size: 11px;
				color: #aaa;
			}
		}
		
		.right{
			font-size: 11px;
				color: #aaa;
			
				svg{
					fill:#f2c9c7;
					cursor:pointer;
					transition: all 0.1s ease-out;
					padding:0px 5px;
					transition: transform 0.3s ease-out;
					width:18px;
					height:18px;
				}
			svg:hover{
					fill:#ed4539;
			 }
			 .up{
				 transform: rotate(-90deg);
			 }
			 .down{
				 transform: rotate(90deg);
			 }
		
			}
			
	}
	main{
		margin: 10px 0px;
		overflow:hidden;
		color: #777;
		transition: 0.2s ease-out;
		font-weight:600;
		display: flex;
		flex-direction: column;
		width: 100%;
		height:100%;
		.question-image-container{
			text-align: center;
			margin-top: 10px;
			.question-image{
				max-width: 75%;
			}
		}
		.question-title-image{
			display: flex;
			justify-content: space-between;
			width: 100%;
			.question-title-container{
				display: flex;
				flex-direction: column;
				width: 90%
			}
		}
		.question-choices{
			font-weight: 300;
			display:flex;
			justify-content: flex-start;
			width:100%;
			ul{
				padding-left:20px;
				list-style-type: none;
				padding:0;
				width:100%;
				.correct{
					color: #9bc9a8;
					transition: 0.2s ease-out;
					border:2px solid $eSpace-green;
				}
				li{
					margin:2px 0px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					font-size: 16px;
					margin:10px 0px;
					background-color: #fafafa;
					padding:10px 20px;
					border-radius: 20px;
				}
				li:after{
					content:"";
					display: inline-block;
					margin:0px 10px;
					border-radius: 100%;
				}

				.correct:after{
					content: url("../exam/correct.svg");
					visibility: visible;
					margin-bottom:5px;
					background-color: transparent;
					width:11px;
					height:11px;
				}
			}
	}
	.tags{
		margin-top:auto;
		margin-bottom:20px;
	}

}
	footer{
		display:flex;
		justify-content: space-between;
    width:100%;
		svg{
			fill:#C0CCE8;
			transition: 0.2s ease-out;
		}
		span{
			padding: 0px 5px;
			color: #777;
			transition: 0.2s ease-out;
			font-weight:600;
		}
		.type:before{
			content: "";
			display: inline-block;
			width:12px;
			height:12px;
			border-radius: 100%;
			background-color: #C0CCE8;
			margin:0px 4px;
			transition: 0.2s ease-out;
		}
	}
}
.long{
		grid-row-end: span 4;
		main{
			height:100%;
			margin-top: 20px;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
		}
}
.question:hover , .long{
	box-shadow: 6px 9px 13px $light-blue;
	main{
		color:#2C2D58;
		.question-choices ul .correct{
			color: $eSpace-green;
		}
		}
	footer{
		svg{fill:#81A0E8;}
		span{color:#2C2D58;}
		.type:before{background-color: #81A0E8;}
	}
	}
