@import "../breakpoints.scss";

.hero{
    .scroll-down{
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-decoration: none;
        @include media(">tablet"){
            display: none;
        }
        .mouse{
            width: 1.25rem;
            height: 2.5rem;
            border: 2px solid #454545;
            border-radius: 1.8rem;
            margin-bottom: 0.1rem;
            display: flex;
            span{
                width: 0.65rem;
                height: 0.65rem;
                background-color: #454545;
                display: block;
                border-radius: 50%;
                margin: auto;
                animation: move-wheel 1s linear infinite;
            }
        }
        .arrow{
            span{
                display: block;
                width: 0.65rem;
                height: 0.65rem;
                margin-bottom: 0.3rem;
                border: 2px solid transparent;
                border-right-color: #454545;
                border-bottom-color: #454545;
                transform: rotate(45deg);
                animation: arrow-down 1s alternate infinite;
            }
        }
        .scroll-text{
            color: #454545;
            text-decoration: none;
            font-size: 0.84rem;
        }
    }
    
}

@keyframes move-wheel{
    0%{
        opacity: 0;
        transform: translateY(1rem);
    }
    100%{
        opacity: 1;
        transform: translateY(-1rem);
    }
}

@keyframes arrow-down{
    0%{
        opacity: 0;
    }
    25%{
        opacity: 0.25;
    }
    50%{
        opacity: 0.5;
    }
    75%{
        opacity: 0.75;
    }
    100%{
        opacity: 1;
    }
}