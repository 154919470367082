@import "../index.scss";
@import url('https://fonts.googleapis.com/css?family=Pacifico&display=swap');
.guide-page{
  .guide-container{
    width:60%;
    height:100vh;
    display: grid;
    grid-template-columns: auto 500px auto;
    grid-template-rows: auto 500px auto;
    .guide-info{
      grid-column-start: 2;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .info, .instructions{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
      }
      .info{
        color: #31346B;
        border-left: 10px solid $yellow;
        padding:0px 10px;
        .exam-title{
          font-size:2em;
          margin:10px 0px;
        }
        span{
          font-weight: lighter;
        }
        }
        }
        .instructions{
          .instructions-title{
            margin:0px;
            font-weight: lighter;
            color: #31346B;
            font-family: 'Pacifico', cursive;
          }
          ul{
            list-style-type: none;
            color: $text-dark;
            padding-left:0px;
            li{
              margin: 5px 0px;
              display: flex;
              justify-content: flex-start;
              align-items: baseline;
            }
            li:before{
              content: url("target.svg");
              display: inline-flex;
              padding:5px;

            }
          }
        }
        .start{
          display: flex;
          justify-content: space-between;
          align-items: center;
          button{
            cursor:pointer;
            border:none;
            outline: none;
            background-color: $main-blue;
            border-radius: 20px;
            padding:10px 20px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            margin-right:20px;
            box-shadow: 3px 3px 10px $eSpace-light-blue;
            transition: 0.2s ease-out;
            span{
              font-weight: bold;
              color: #fff;
              margin:0px 10px;
              font-family: "Roboto", sans-serif;
            }
            svg{
              fill:#fff;
              width:12px;
              height:12px;
              padding:5px;
            }
          }
          button:hover{
            box-shadow: 3px 3px 25px $eSpace-light-blue;
          }
          a,a:visited{
            text-decoration: none;
            color: $main-blue;
            text-decoration: underline;

          }
        }
      }
      img{
        height:100vh;
        position: absolute;
        bottom:0px;
        top:0px;
        right:-100px;
    }
  }
@media only screen and (max-width: 1050px) {
    ul{
      width:50%;
    }
}
@media only screen and (max-width: 900px) {
  .guide-info{
    margin-left:50px;
  }
}
@media only screen and (max-width: 800px) {
  ul{
    width:100%;
  }
  .guide-info{
    margin-left: 20%;
  }
  .image_guide{
    display: none;
  }
 
}
