@import "../index.scss";
@import "../breakpoints.scss";

body{
  scroll-behavior: smooth;

}
.mark-exam{
  grid-template-columns: 300px auto;
  width:100%;
  grid-column-gap: 50px;
  @include media('<tablet'){
    display: flex;
    flex-direction: column;
    height: auto;
    margin-bottom: 130px;
  }
  .application-info{
    padding:0px 20px;
    margin: 20px;
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end:3;
    height:100%;
    color: $text-dark;
    @include media("<tablet"){
      height: auto;
    }
    #toTop, #submitExamReview{
      cursor:pointer;
      position: fixed;
      bottom: 30px;
      background-color: $main-blue;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0px 5px 10px #aaa;
      transition: 0.2s ease-out;
    }
    #toTop{
      left:40px;
      border-radius: 100%;
      width:50px;
      height:50px;
      text-decoration: none;
      svg{
        fill:#fff;
        width:18px;
        height:18px;
      }
    }
    #submitExamReview{
      right:40px;
      border-radius:20px;
      width:80px;
      height:40px;
      border:none;
      outline: none;
      font-weight: bold;
    }
    #toTop:hover, #submitExamReview:hover{
      box-shadow: 0px 10px 20px #aaa;
    }

    .show{
      visibility: visible;
    }
    ul{
      padding:0px;
      list-style-type: none;

      li{
        margin: 5px 0px;
        cursor: pointer;
        transition: 0.2 ease-out;
        a{
          text-decoration: none;
          color: #aaa;
        }
      }
      li:hover{
        a{color: $text-dark;}
      }
      .done{
        text-decoration: line-through;

      }
    }

  }
  .mark-question{
    grid-column-start:2;
    grid-column-end: 3;
    border-bottom: 1px solid #eee;
    padding: 20px 0px;
    box-sizing: border-box;
    margin: 0px 150px 0px 0px;
    @include media('<tablet'){
      width: 90%;
      margin: 0 auto;
    }
    p{
      font-size: 18px;
    }
    .view-image{
      color: #4356D5;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .markInput{
      width:100%;
      display: flex;
      justify-content: flex-end;
      input{
        border:none;
        background-color: transparent;
        border-bottom: 2px solid $eSpace-light-blue;
        width:30px;
      }
      span{
        font-weight: bold;
      }
    }
    .essay{
      border-left: 5px solid $yellow;
      padding: 10px 20px;
      white-space: pre-wrap;
    }
    ul{
      list-style-type: none;
      padding:0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      li{
        background-color: #fff;
        padding: 10px 20px;
        border-radius: 20px;
        margin: 10px 0px;
        box-shadow: 0px 3px 5px #eee;
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width:50%;
      }
      li:after{
        content:"_";
        visibility: hidden;
        display: inline-block;
        padding: 0px 5px;
      }
      .wrong{
        color: $eSpace-red;
        border: 2px solid $eSpace-red;
      }
      .wrong:after{
        content:url("./wrong.svg");
        visibility: visible;
      }
      .correct{
        color:$eSpace-green;
        border: 2px solid $eSpace-green;
      }
      .correct:after{
        content:url("./correct.svg");
        visibility: visible;
      }
    }
  }
}
