@import "../index.scss";

header {
    h1{
        text-decoration: underline;
    }

}
.disable{
  pointer-events: none;
  opacity: 0.5;
}
.metadata {
  header{
    margin-left: 20px;
    width: 92%;
    h1{
      margin-bottom: 0px;
    }
  }

  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0px 3px 30px #a4aff6;
  width: 90%;
  padding: 10px;
  padding-bottom: 30px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .input {
    border-bottom: medium;
  }
  .desc{
    width: 65%;
    word-wrap: break-word;
    padding: 21px;
    
    
  }
  h4{
    width: 150px;
  }
  .flex {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
  
    
  }
  .input {
    margin-left: 20px;
    padding: 21px;
    
  }
}



.questions {
  margin-top: 20px;
  width: 90%;
  padding: 10px;
  margin-left: 40px;
  background-color: #fff;
  box-shadow: 0px 3px 30px #a4aff6;
  .exam_questions {
    padding: 10px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
  }
  .qhead {
    margin-left: 20px;
    display: flex;
    flex-direction: row;
    width: 92%;
    button {
        border-radius: 20px;
        margin-top: 25px;
        border: 1px solid #3a54d3;
        height: 30px;
        margin-left: 5px;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: bolder;
        background-color: #3a54d3;
        color: white;
        cursor: pointer;
        outline: none;
      }
      
  }
  .actions{
    margin-left: auto;
  
  }
}
.head {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media only screen and (max-width: 950px) {
  .exam_questions {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media only screen and (max-width: 550px) {
  .exam_questions {
    grid-template-columns: repeat(1, 1fr);
  }
}


