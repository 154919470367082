@import "./index.scss";
$shadow: #ddd;

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap");
input:focus, textarea:focus, select:focus{
        outline: none;
}
.formContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 60%;
  font-family: "Roboto", sans-serif;
}
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  .elem:last-of-type {
    margin-bottom: 40px;
  }
  .selectContainer {
    width: 100%;
    position: relative;
    margin:30px;
    display:flex;
    align-items: flex-start;
    select{
      width: 100%;
      height: 30px;
      border: none;
      border-bottom: 3px solid $eSpace-light-blue;
      background:none;
      cursor: pointer;
      font-family: "Roboto", sans-serif;
      color: $text-dark;
      font-weight: 500;
      font-size:16px;
      option{
        font-family: "Roboto", sans-serif;
        color: $text-dark;
        font-weight: 500;
        font-size:14px;
      }

    }
  }
  .selectContainer:after {
    content: " ";
    position:absolute;
    top:0;
    right:0;
    bottom:3px;
    width: 20px;
    pointer-events:none;
  }

  .checkbox {
    width:55%;
    margin: 20px 0px 20px 0px;
    display: flex;
    justify-content: flex-start;

    input {
      display: none;
    }
    p {
      font-weight: light;
      color: $text-dark;
      margin: 0;
    }
    label {
      height: 20px;
      width: 20px;
      background-color: #ddd;
      border: 2px solid #ddd;
      display: block;
      margin: 0px 20px;
      border-radius: 3px;
      img {
        width: 20px;
        height: 20px;
        visibility: hidden;
      }
    }
    label:hover {
      border: 2px solid $eSpace-light-blue;
    }
    input[type="checkbox"]:checked {
      + label {
        background: $eSpace-light-blue;
        border: 2px solid $eSpace-light-blue;
        img {
          visibility: visible;
        }
      }
    }
  }
  a {
    text-decoration: none;
    color: $eSpace-light-blue;
    font-weight: light;
    font-size: 14px;
    margin: 5px 15px;
    outline:none;
    border:none;
    background-color: transparent;
    padding:10px;
    cursor:pointer;
  }
  .submitBtn {
    width: 50%;
    margin: 20px;
    margin-top: 0;
    height: 40px;
    border: none;
    color: #fff;
    background-image: linear-gradient(
      to right,
      $text-dark 0%,
      $brand-color 50%,
      $eSpace-light-blue 100%
    );
    font-weight: bold;
    cursor: pointer;
  }
  .submitBtn:hover {
    background-image: linear-gradient(
      to right,
      $text-dark 20%,
      $brand-color 70%,
      $eSpace-light-blue 100%
    );
  }
}

.inputContainer {
  position: relative;
  margin: 10px 20px;
  overflow: hidden;
  width: 50%;
  padding:20px 0px;
  input {
    background-color: transparent;
    border: none;
    border-bottom: 3px solid #aaa;
    height: 40px;
    width: 100%;
    outline: none;
    position: absolute;
    bottom:0px;
  }
  label {
    position: absolute;
    bottom:10px;
    left: 0%;
    width: 100%;
    height: 100px;
    pointer-events: none;
    font-weight: 500;
    padding: 20px;
    .label-content {
      color: $text-dark;
      position: absolute;
      bottom: 0px;
      left: 0px;
      transition: all 0.5s ease;
    }
  }
  label::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-bottom: 4px solid $eSpace-light-blue;
    left: 0px;
    bottom: -1px;
    transform: translateX(-100%);
  }

  input:focus {
    //border: none;
    + label {
      .label-content {
        transform: translateY(-150%);
        font-size: 14px;
        color: #aaa;
      }
    }
    + label::after {
      transform: translateX(0%);
    }
  }

  input:valid {
    border: none;
    + label {
      .label-content {
        transform: translateY(-150%);
        font-size: 14px;
        color: #aaa;
      }
    }
    + label::after {
      transform: translateX(0%);
    }
  }
}
@media only screen and (max-width: 900px) {
  .formContainer {
    width: 100%;
    form {
      width: 80%;
    }
  }
}
