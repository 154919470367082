@import "../index.scss";

.category-group{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .category-group-badge{
    background-color: #EBECF0;
    border-radius: 2em;
    color: #172B4D;
    display: inline-block;
    font-size: 12;
    font-weight: normal;
    width: auto !important;
    padding: 0.16666666666667em 0.5em;
    text-align: center;
  }
}

.category-multi-select {
  .select__control {
    border: none;
    border-bottom: 3px solid #59b3d5;
    box-shadow: none;
    margin: 0;
    &:hover{
      border-bottom: 3px solid #59b3d5;
    }
    .select__value-container{
      padding-left: 2px;
    }
  }
}
.error.msg{
  margin-bottom: 10px;
}