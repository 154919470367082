@import "../index.scss";
@import "../breakpoints.scss";

  .wrapper{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin:0 auto;
    width:90%;
    height:90vh;
    @include media('<tablet'){
      margin: 0;
    }
    .que-container{
      width:100%;
      padding:20px;
      padding-left:0px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;
      grid-auto-rows: minmax(100px, auto);
      grid-column-gap: 20px;
      grid-row-gap: 20px;
      @include media('<tablet'){
        padding-right: 0;
      }
  }

  .pages{
    margin-top: 0px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg{
      padding: 5px;
      fill: #333;
      height:18px;
      width:18px;
      cursor: pointer;
    }
    svg:hover{
      fill: $main-blue;
    }
    #rotate{
       transform: rotate(180deg);
    }
    span{
      min-width: 20px;
      min-height: 20px;
      padding:5px;
      margin:5px;
      cursor:pointer;
      background-color: #eee;
      color: #333;
      border-radius:100%;
      transition: 0.1s ease-out;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    span:hover{
      color: $main-blue;
    }
    .active{
      background-color: $main-blue;
      color: #fff;
    }
    .active:hover{
      color: #fff;
    }
  }
}
.emptyState{
  grid-column-end: span 3;
  display: flex;
  width:100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #888;
    margin-bottom:50px;
  }
  img{
    height:200px;
    width:200px;
    opacity: 0.8;
  }
}

@media only screen and (max-width: 800px) {
  .wrapper {
    .que-container{
      grid-template-columns: repeat(2, 1fr);
    }
    .inputContainer{
      margin-left:30px;
    }
  }
}
@media only screen and (max-width: 550px) {
  .wrapper .que-container{
    grid-template-columns: repeat(1, 1fr);
  }
}
