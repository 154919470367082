@import "../index.scss";
@import '../breakpoints.scss';
@import url("https://fonts.googleapis.com/css2?family=Carter+One&display=swap");

.submitted-page {
  display: flex;
  flex-direction: row;
  @include media('<1025px'){
    justify-content: center;
}
  .submitted-container {
    width: 60%;
    height: 100vh;
    display: flex;
    align-items: center;
    @include media('<1025px'){
        width: 85%;
        justify-content: center;
    }
    .submitted-info {
      margin-left: 10rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      @include media('<1025px'){
        margin: 0;
    }
      .info-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 3rem;
      }
    }
    .info-section {
      .info-title {
        margin: 0px;
        font-weight: lighter;
        color: #31346b;
        font-family: "Carter One", cursive;
        margin-bottom: 1rem;
      }
      span{
          color: #444;
      }
    }
    .view-applications {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        cursor: pointer;
        border: none;
        outline: none;
        background-color: $main-blue;
        border-radius: 20px;
        padding: 10px 20px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-right: 20px;
        box-shadow: 3px 3px 10px $eSpace-light-blue;
        transition: 0.2s ease-out;
        span {
          font-weight: bold;
          color: #fff;
          margin: 0px 10px;
          font-family: "Roboto", sans-serif;
        }
        svg {
          fill: #fff;
          width: 12px;
          height: 12px;
          padding: 5px;
        }
      }
      button:hover {
        box-shadow: 3px 3px 25px $eSpace-light-blue;
      }
      a,
      a:visited {
        text-decoration: none;
        color: $main-blue;
        text-decoration: underline;
      }
    }
  }
  img {
    height: 100vh;
    margin-left: -100px;
    @include media('<1025px'){
        display: none;
    }
  }
  
}

