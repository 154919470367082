@import "../index.scss";

.fileInput{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 80%;
    .input{
    margin-left: auto;
    padding: 20px;
  
    }
  }
  .footerActions{
    display: flex;
    width:80%;
    justify-content: flex-end;
    align-items: center;
    background-color: transparent;
    margin: 20px 0;
    button{
      border:none;
      outline:none;
      background-color: transparent;
      color: $text-dark;
      padding:10px;
      cursor:pointer;
      margin-right:5px;
    }
  
    .import-submit{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25%;
      margin-top: 0;
      height: 40px;
      border: none;
      color: #fff;
      background-image: linear-gradient(to right, #252b33 0%, #0071b3 50%, #59b3d5 100%);
      font-weight: bold;
      cursor: pointer;
    }
  }
  .submitExcel{
    width: 200px;
    margin: 20px 0px 20px 0px;
    height: 40px;
    border: none;
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center ;
    justify-content: center;
    background-image: linear-gradient(
      to right,
      $text-dark 0%,
      $brand-color 50%,
      $eSpace-light-blue 100%
    );
    font-weight: bold;
    cursor: pointer;

  }
  .submitExcel:hover {
    background-image: linear-gradient(
      to right,
      $text-dark 20%,
      $brand-color 70%,
      $eSpace-light-blue 100%
    );
  }
  input[type="checkbox"]{
      height: 20px;
      width: 20px;
      margin-left: auto;
  }
  .checboxLabel{
      padding: 5px 0px 5px 0px;
  }
  .disabledDiv{
    pointer-events: none;
    opacity: 0.5;

  }
  .defaultCheck{
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 80%;
    margin-top: 20px;
  }
  input[type="file"]::-webkit-file-upload-button {
    border: none;
    padding: 5px 15px;
    color: #fff;
    font-size: 1em;
    transition: all .4s;
    cursor: pointer;
    border-radius: 20px;
    outline: none;
    background-image: linear-gradient(
      to right,
      $text-dark 0%,
      $brand-color 50%,
      $eSpace-light-blue 100%
    );
    font-weight: bold;
  }
  input[type="file"]::-webkit-file-upload-button:hover {
    background-image: linear-gradient(
      to right,
      $text-dark 20%,
      $brand-color 70%,
      $eSpace-light-blue 100%
    );
  }
  .sample-csv {
    margin-top: 30px;
    width: 80%;
    

  }
  .right{
    display: flex;
    justify-content:flex-end;
  }
  .note{
    font-weight: bold;
  
  }
 
  @media only screen and (max-width: 600px) {
    .content .wrapper .animated{
        width: 90%;
        position: absolute;
        left: 10px;
        right:10px;
      }
  }
  @media only screen and (max-width: 400px) {
    .content .wrapper .animated{
        width: 100%;
        position: absolute;
        left: 0px;
        right:0px;
      }
  }