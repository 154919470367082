@import "../index.scss";
@import "../breakpoints.scss";

#reset_image{
  max-width: 10%
}
#question_image_input{
  max-width: 40%;
}
#question_image_title{
  max-width: 30%;
}
.category-container{
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 10px 0;
}
// submit popup
.popup{
  position: absolute;
  .modal {
     
      z-index: 9999;
      min-height: 150px;
      position: relative;
      width: 22%;
      height: 150px;
      top: 37.5%;
      margin: 0 auto;
      color: $text-dark;
      background-color: #fff;
      box-shadow: 0px 3px 30px #A4AFF6;
      border-radius: 10px;
      min-width: 320px;
      .errorContainer{
        width:100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      header {
       border-radius: 10px 10px 0 0;
       padding: 10px 20px;
       display:flex;
       align-items: center;
       justify-content: space-between;
        h3{
          margin: 10px 0px;
        }
        
      }
      header{
        border-bottom: 3px solid #f9f9f9;
      }
      .formContainer{
        position:relative;
        width:100%;
            
        }
  
        form{
        
          width:80%;
          
          .footer{
            
            display: flex;
            width:100%;
            justify-content: center;
            align-items: center;
            background-color: transparent;
          
            .popupButton{
              
              height: 30px;
              width: 100px;
              border: 1px solid #3a54d3;
              cursor: pointer;
              background-color: #3a54d3;
              color: #fff;
              border-radius: 10px;
            }
            .popupButton:hover{
              color: #f5ee9e;
              border: 1px solid #f5ee9e;
            }
          }
        }
        .alert{
         margin-bottom: 10px;
          color: red;
        }
  
      }
  
    }




.content .wrapper .animated{
    width: 40%;
    position: absolute;
    min-width:400px;
    right: 30%;
    left:30%;
  .modal {
   margin-top: 30px;
    z-index: 9999;
    color: $text-dark;
    background-color: #fff;
    box-shadow: 0px 3px 30px #A4AFF6;
    border-radius: 10px;
    .errorContainer{
      width:100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    header {
     border-radius: 10px 10px 0 0;
     padding: 10px 20px;
     display:flex;
     align-items: center;
     justify-content: center;
      h3{
        margin: 10px 0px;
      }
      
    }
    header{
      border-bottom: 3px solid #f9f9f9;
    }
    .formContainer{
      position:relative;
      width:100%;
      margin: 10px 0;

      .menu{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        margin-top: 10px;
        p{
         font-weight: bold; 
        }
        label{
          font-size: 14px;
        }
        #type-selector{
          font-size: 0.9rem;
          min-width: 10rem;
          .type-select__control{
            border: none ;
            // border-bottom: 3px solid #59b3d5;
            box-shadow: none;
            .type-select__value-container{
              justify-content: center;
            }
            .type-select__dropdown-indicator{
              color: hsl(0, 0%, 40%);
            }
            .type-select__dropdown-indicator:hover{
              color: hsl(0, 0%, 20%);
            }
          }
        }
      }

      form{
        width:80%;
        textarea{
          width: 100%;
          border:none;
          padding: 10px;
          border: 3px solid #59b3d5;
          margin:10px;
          border-radius:5px;
          font-family: "roboto", sans-serif;
          font-size: 12px;
          box-sizing: border-box;
          height:120px;
        }
        .inputContainer {
          width:100%;
          input{
            padding:0px 10px;
          }
          label{
            left:100%;
          }
          label::after{
            transform: translateX(-100%);
          }
        }
        .radio .radio-choices label{
          background-color: #f9f9f9;
        }
        .radio .radio-choices label:hover{
          background-color: $eSpace-light-blue;
        }
        .radio .radio-choices input:checked{
          +label{
            color: #fff;
            background-color: $eSpace-light-blue;
          }
        }
        .footer{
          display: flex;
          width:100%;
          justify-content: flex-end;
          align-items: center;
          background-color: transparent;
          margin-top: 50px;
          button{
            border:none;
            outline:none;
            background-color: transparent;
            color: $text-dark;
            padding:10px;
            cursor:pointer;
            margin-right:5px;
          }
          .submitBtn{
            margin:10px 0px;
            width: 150px;
            cursor: pointer;
            color: #fff;
          }
        }
      }

    }

  }

}
#inline-form {
  width:100%;
}

.radio, .file-input{
  width:100%;
  margin: 20px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content:space-between;
  .span-box{
    display: flex;
  }
  .time-picker{
    width: 40px;
    margin: 5px;
  }
  .time-label{
    background-color: #f9f9f9;
    color: #777;
  }

  p{
    font-size: 14px;
  }
  .radio-choices{
    input{
      display: none;
    }
    label{
      cursor: pointer;
      padding: 5px 10px;
      width:30%;
      background-color: #f9f9f9;
      color: #777;
      transition: 0.2s ease-out;
    }
    label:hover{
      color: #fff;
      background-color: $eSpace-light-blue;
    }

  }
}
