@import "../index.scss";
@import "../breakpoints.scss";

.wrapper{
  .filter{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include media('<tablet'){
      padding-left: 0;
      padding-right: 0;
    }
    .row{
      width:100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .search{
        display: flex;
        justify-content: center;
        align-items: baseline;
        .searchBtn{
          border:none;
          height:32px;
          width:32px;
          margin-left:0;
          margin-bottom:0px;
          border-radius: 100%;
          position: relative;
          left:-30px;
          bottom: 5px;
        }
        .inputContainer{
          width:300px;
          margin-left:0px;
          margin-right:0px;
          margin-bottom: 0px;
          input{
            width: 300px;
          }
        }
      }


      button{
        color: $eSpace-light-blue;
        font-weight: bold;
        background-color: transparent;
        outline:none;
        border:1px solid $eSpace-light-blue;
        border-radius: 5px;
        height: 40px;
        width: 80px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        transition: 0.2s ease-out;
        cursor: pointer;
        svg{
          width:12px;
          height:12px;
          fill: $eSpace-light-blue;

        }
      }
      button:hover , .active{
        color: #fff;
        background-color: $eSpace-light-blue;
        svg{
          fill: #fff;
        }
    }


}
.filterClear{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin:30px 0px 0px 0px;
  border-top: 1px solid #eee;
  padding-top: 30px;
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 30px 0px 0px;
  }
  .filterClearText{
    font-size: 14px;
    color: #666;
    cursor:pointer;
    transition: 0.2s ease-out;
  }
  .filterClearIcon{
    margin:0px 5px;
    width:14px;
    height:14px;
    fill: $eSpace-red;
    transition: 0.2s ease-out;
  }
}
.filterTextUnderline:hover{
 
    color: #333;
    text-decoration: underline;
    text-decoration-style: dotted;
  
}
      .filterOptions{
        margin-top:10px;
        min-height: 100px;
        width:100%;
        display: flex;
        justify-content: space-between;
        align-items: start;
        flex-wrap: wrap;
        margin-bottom: 40px;
        .toggleSwitch {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p{
            font-size: 14px;
          }
          .switch{
            position: relative;
            display: inline-block;
            width:80px;
            height:24px;
            input{
              opacity: 0;
              width:0;
              height:0;
            }
            .slider{
              position: absolute;
              cursor: pointer;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              background-color: #ddd;
              transition: 0.4s ease-out;
              border-radius: 34px;
            }
            .slider:before{
              content: "";
              position: absolute;
              height:16px;
              width:16px;
              border-radius: 100%;
              left:4px;
              bottom:4px;
              background-color: #fff;
              transition: 0.4s ease-out;
            }
            input:checked + .slider {
              background-color: $eSpace-light-blue;
            }
            input:checked + .slider:before{
              transform: translateX(40px);
            }
          }
        }

        button{
          background-color: transparent;
          border:none;
          outline: none;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: transparent;
          color: $eSpace-light-blue;
          width:200px;
          height:30px;
          border-radius: 5px;
          font-weight: bold;
          border: 1px solid $eSpace-light-blue;
          cursor:pointer;
          transition: 0.2s ease-out;
          svg{
            fill: $eSpace-light-blue;
            width:14px;
            height:14px;
            padding: 0px 0px 0px 5px ;
          }
        }
        button:hover{
          background-color: $eSpace-light-blue;
          color: #fff;
          svg{
            fill: #fff;
          }
        }
        .time-div{
          width: 10%;
        }
        .col{
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-start;
          .radio{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          }
          .radio .radio-choices label{
            background-color: #fff;
          }
          .radio .radio-choices label:hover{
            background-color: $eSpace-light-blue;
          }
          .radio .radio-choices input:checked{
            +label{
              color: #fff;
              background-color: $eSpace-light-blue;
            }
          }
          span{
            width: 80%;
            input{
              width: 100%;
            }
          }

            input{
             margin: 0;
             border:none;
             border-bottom: 3px solid $eSpace-light-blue;
             width:80%;
             padding: 10px;
             margin:10px 0px;
             background-color: transparent;
            }
        }
      }

    }
  }
  @media only screen and (max-width: 550px) {
    .filterOptions{
      width: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .col{
        width: 100%;
      }
    }
  }
  
.col.category-filter{
  width: 250px;
  align-self: normal;
  margin-top: 10px;
  .add-category{
    width: 100%;
  }
}