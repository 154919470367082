@import "../index.scss";
@import "../breakpoints.scss";
@import url('https://fonts.googleapis.com/css?family=Rubik');


.exam-container{
  margin:0px;
  padding:0px;
  height:100vh;
  display: flex;
  @include media("<tablet"){
    flex-direction: column;
  }
  * {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .exam-side-panel {
    @include media("<tablet") {
      flex-direction: row-reverse;
      justify-content: space-between;
      padding: 7px;
    }
    .actions{
      a{
        position: absolute;
        bottom: 0px;
        font-size: 14px;
        margin-bottom: 15px;
        margin-top: 15px;
        text-decoration: underline;
        text-decoration-style: dotted;
        text-decoration-color: $eSpace-light-blue;
        .d-lg{
          margin-left: -79px;
          color: #fff;
        }
        .d-sm{
          display: none;
        }
        @include media('<tablet'){
          color: white;
          text-decoration: none;
          .d-lg{
            display: none;
          }
          .d-sm{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.3em;
            color: #fff;
            font-weight: bolder;
            transition: 0.15s;
            svg{
              fill: #fff;
              margin-right: 5px;
            }
          }
        }
      }
    }
    .submit-exam{
      border:none;
      outline: none;
      background-color: $main-blue;
      color:$yellow;
      font-size: 1.1em;
      border:2px solid $yellow;
      border-radius: 10px;
      padding:10px 55px;
      position: absolute;
      bottom:40px;
      @include media("<tablet"){
        position: fixed;
        color: white;
        bottom: 20px;
        right: 20px;
        background: linear-gradient(45deg, #2E3EA7 40%, #4356D5);
        border-radius: 30px;
        padding: 9px;
        border: none;
        font-size: 1em;
      }
    }
    .save-answers-button {
      border:none;
      outline: none;
      background-color: transparent;
      color:$yellow;
      font-size: 1.1em;
      border:2px solid $yellow;
      border-radius: 10px;
      padding:10px 22px;
    }
    #current a{
      color: white;
    }
    button:hover{
      color:$main-blue;
      background-color: $yellow;
    }
    background-color: $main-blue;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-family: "Roboto", sans-serif;
    .side-panel-timer{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 50px auto 25px auto;
      h1{
        font-size:2.5em;
        font-family: 'Rubik', sans-serif;
        margin:0px;
      }
      span {
        margin:0px;
        padding:0px;
      }
      @include media("<tablet"){
        margin: 5px;
        h1{
          font-size: 1.8em;
        }
        span{
          font-size: 0.8em;
        }
      }
    }
    .save-question-list{
      text-align: center;
    }

    .side-panel-list{
      margin: 25px auto 50px auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow-y:scroll;
      scrollbar-color: #ddd transparent;
      scrollbar-width: thin;
      scroll-behavior: smooth;
      max-height:400px;
      @include media("<tablet"){
        display: none;
      }
      ul{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding-left:10px;
        list-style-type: none;
        li{
          margin: 5px 0px;
          cursor: pointer;
          a{
            text-decoration: none;
            color:#A5B6DE;
          }
        }
        li:hover{
          +a{
            color:#fff;
          }
        }
        li:before{
          content: "";
          display: inline-block;
          width:16px;
          height:14px;
          border-radius: 100%;
          background-color: rgba(0,0,0,0.1);
          margin:0px 10px;
          padding-top: 2px;
        }
        .active{
          a{
            color:#fff;
          }
        }
        .done{
          a{
            text-decoration: line-through;
          }
        }
        .done:before{
          content: url("./checkmark.svg");
          display: inline-flex;
          align-items: center;
          justify-content: center;
          background-color: $yellow;
        }
      }
    }

  }
  .exam-slider{
    position: absolute;
    margin-top:20px;
    right:30px;
    height: 95vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .slider-bar{
      width:7px;
      height:60px;
      background-color: #ddd;
      border-radius: 20px;
      margin: 5px 0px;
    }
    #current{
      background-color: $main-blue;
    }
  }
  .question-container{
    height:100%;
    width:100%;
    scroll-behavior: smooth;
    display: grid;
    grid-template-columns: 150px auto 150px;
    grid-gap: 10px;
     grid-auto-rows: minmax(100vh, auto);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    @include media("<tablet", ">500px"){
      grid-template-columns: 0px auto 150px;
    }
    @include media("<500px"){
      grid-template-columns: 0px 100px 150px;
    }
    .exam-question{
      grid-column-start: 2;
      grid-column-end: 3;
      // max-width: 600px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      color: $text-dark;
      @include media("<tablet", ">500px"){
        grid-column-start: 1;
        grid-column-end: 3;
      }
      @include media("<500px"){
        grid-column-start: 1;
        grid-column-end: 4;
      }
      .question-body{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 50%;
        @include media('<tablet'){
          width: 100%;
          height: 90%;
        }
        p{
          padding:20px;
          font-weight: bold;
          font-size: 1.3em;
          @include media("<tablet"){
            padding:0px 20px;
            font-weight: bold;
            font-size: 1.15em;
            margin-top: -120px;
            max-height: 35%;
          }
        }
        form{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          @include media('<tablet'){
            max-height: 35%;
          }
          .multiple-tip{
            padding: 5px 0px 15px 20px;
            color: #6c757d;
            font-size: 14px;
            @include media("<tablet"){
              font-size: 13px;
            }
          }
          .checkbox{
            p{
              padding:0px;
            }
          }
          textarea{
            resize: none;
            border:none;
            width:100%;
            height:200px;
            border: 2px solid #ddd;.question-img{
              // max-width: 40%;
              max-height: 100px;
            }
            border-radius: 10px;
            padding:10px;
            font-family: "Roboto", sans-serif;
            @include media("<450px"){
              max-width: 190px;
            }
          }
          textarea:focus{
            border: 2px solid $eSpace-light-blue;
          }
        }
      }
      .question-img-lg{
        box-shadow: 0 4px 8px 0 rgba(67, 86, 213, 0.2), 0 6px 20px 0 rgba(67, 86, 213, 0.70);
        max-width: 40%;
        max-height: 90%;
        margin-left: 40px;
        cursor: zoom-in;
        @include media('<tablet'){
          display: none;
        }
      }
      .question-img-lg:hover{
        transform: scale(1.1);
      }
      .question-img-sm{
        display: none;
        @include media('<tablet'){
          display: flex;
          padding: 0px 0px 10px 20px;
          color: #4356D5;
          align-items: center;
        }
      }
      .question-img-sm:hover{
        transform: scale(1.1);
      }
    }
  }
}

.question-choice{
  width:100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  input{
    display: none;
    +label{
      cursor:pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin:10px;
    }
    +label:before{
      content:"";
      display: inline-block;
      height: 15px;
      min-width: 15px;
      background-color: #ddd;
      border: 2px solid #ddd;
      margin:0px 10px;
      padding:2.5px;
    }
  }
  input[type="radio"]{
    +label:before{
      content:"";
      border-radius: 100%;

    }
    +label:hover::before{
      border: 2px solid $eSpace-light-blue;
    }
  }
  input[type="checkbox"]{
    +label:before{
      border-radius: 3px;
    }
    +label:hover::before{
      border: 2px solid $eSpace-light-blue;
    }
  }
  input[type="checkbox"]:checked, input[type="radio"]:checked{
    + label:before {
      content: url("checked.svg");
      background: $eSpace-light-blue;
      border: 2px solid $eSpace-light-blue;

    }
  }
}

  .side-panel-list::-webkit-scrollbar, textarea::-webkit-scrollbar{
    background-color: transparent;
    width:6px;
  }
    .side-panel-list::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb{
    background-color: #A5B6DE;
    border-radius: 20px;
  }
    .side-panel-list::-webkit-scrollbar-thumb:active, textarea::-webkit-scrollbar-thumb:active{
    background-color: $yellow;

  }
    .side-panel-list::-webkit-scrollbar-track,textarea::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
  }
