@import "../index.scss";

.exam_title{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 80%;

    input{
        width: 60%;
        border: none;
        border-bottom: 3px solid #59b3d5;
        outline: none;
        margin-left: 30px;
    }

    
}
.exam_description{
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    width: 80%;

    
    textarea{
        width: 100%;
        padding: 10px;
        border: 3px solid #59b3d5;
        border-radius:5px;
        font-family: "roboto", sans-serif;
        font-size: 14px;
        box-sizing: border-box;
        height:120px;
    }
}
.disabledDates{
    pointer-events: none;
    opacity: 0.5;
    width: 80%;
}

.start_date{
    display: flex;
    padding: 5px;
    flex-direction: row;
    justify-content: start;
    width: 100%;
   
}
.end_date{
    display: flex;
    padding: 5px;
    flex-direction: row;
    justify-content: start;
    width: 100%;
  
}
.picker{
    margin-left: auto;
    
}
.exam_time{
    input{
        margin-left: 20px;
        width: 30%;
    }
    .calculated-time{
      font-size: 15px;
      margin: 2px 0px 0px 2px;
      color: #59b3d5;
    }

}
.disabledTime{
    pointer-events: none;
    opacity: 0.5;
}
.time{
    display: flex;
    flex-direction: row;
    justify-content: start;
}

.footerActions{
    display: flex;
    width:80%;
    justify-content: flex-end;
    align-items: center;
    background-color: transparent;
    margin: 20px 0;
    button{
      border:none;
      outline:none;
      background-color: transparent;
      color: $text-dark;
      padding:10px;
      cursor:pointer;
      margin-right:5px;
    }
  
    .addExamButton{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 25%;
      margin-top: 0;
      height: 40px;
      border: none;
      color: #fff;
      background-image: linear-gradient(to right, #252b33 0%, #0071b3 50%, #59b3d5 100%);
      font-weight: bold;
      cursor: pointer;
    }
  }
  .add-exam-animated{
  left: 30%;

  }
  .modal {
    margin-top: 30px;
     z-index: 9999;
     color: $text-dark;
     background-color: #fff;
     box-shadow: 0px 3px 30px #A4AFF6;
     border-radius: 10px;
     .errorContainer{
       width:100%;
       display: flex;
       justify-content: center;
       align-items: center;
     }
     header {
      border-radius: 10px 10px 0 0;
      padding: 10px 20px;
      display:flex;
      align-items: center;
      justify-content: center;
       h3{
         margin: 10px 0px;
       }
       
     }
     header{
       border-bottom: 3px solid #f9f9f9;
     }
     .formContainer{
       position:relative;
       width:100%;
       margin: 10px 0;
 
       form{
         width:80%;
         textarea{
           width: 100%;
           border:none;
           padding: 10px;
           border: 3px solid #59b3d5;
           margin:10px;
           border-radius:5px;
           font-family: "roboto", sans-serif;
           font-size: 12px;
           box-sizing: border-box;
           height:120px;
         }
      
        
     
       }
 
     }
     .menu{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      p{
       font-weight: bold; 
      }
      label{
        font-size: 14px;
      }
      #type-selector{
        font-size: 0.9rem;
        min-width: 10rem;
        .type-select__control{
          border: none ;
          // border-bottom: 3px solid #59b3d5;
          box-shadow: none;
          .type-select__value-container{
            justify-content: center;
          }
          .type-select__dropdown-indicator{
            color: hsl(0, 0%, 40%);
          }
          .type-select__dropdown-indicator:hover{
            color: hsl(0, 0%, 20%);
          }
        }
        .select-label-container{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .exam-select-label{
            padding-top: 2px;
            padding-right: 0.5rem;
          }
          .tooltip-icon{
            font-size: 1.2rem;
          }
        }
      }
    }
 
   }
   .examBtnAdd{
    background: linear-gradient(45deg, #2e3ea7 40%, #4356d5);
    width: 50px;
    height: 50px;
    border-radius: 100%;
    box-shadow: 10px 10px 30px #aaa;
    position: fixed;
    bottom: 50px;
    right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    color: #fff;
    font-weight: bolder;
    cursor: pointer;
    transition: 0.15s;
    transition-timing-function: ease-out;
    svg {
      fill: #fff;
    }
   }
.edit-btn{
  margin-left: auto;
        border-radius: 20px;
        margin-top: 25px;
        border: 1px solid #3a54d3;
        height: 30px;
        padding-left: 10px;
        padding-right: 10px;
        font-weight: bolder;
        background-color: #3a54d3;
        color: white;
        cursor: pointer;
        outline: none;
}
.disable{
  pointer-events: none;
  opacity: 0.5;
}

