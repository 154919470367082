.question-level{
	display:flex;
	justify-content:center;
	align-items:center;

	.bar{
		width:40px;
		height:10px;
		background-color: #ddd;
		margin:0px 2px;
		border-radius: 10px;
	}
	.green{
		background-image: linear-gradient(45deg, #085A10, #1FB214);
	}
	.yellow{
		background-image: linear-gradient(45deg,#B5A61D ,#FBF31D );
	}
	.red{
		//background-image:linear-gradient(45deg, , #5A0808);
		background-image:linear-gradient(45deg, #5A0808, #B21414);
	}
}
