@import "./index.scss";
$red: #D13F28;
$main-blue: #4356D5;
$dark-blue: #2E3EA7;
$light-blue: #59b3d5;
$dark-text: #0d1321;
svg.disabled{
  opacity:0.3;
  fill:#acacac !important;
}
.animated {
  z-index:999;
  width: 40%;
  height:100vh;
  position: absolute;
  top:0;
  bottom:0;
  right:0;
    .profile-info {
      height:100%;
      background: linear-gradient( 45deg,#F5F0B8 40%, #FBF9E0 60%);
      display: flex;
      flex-direction:column;
      align-items:center;
      padding:20px;
      .formContainer{
        position:static;
        width:100%;
        justify-content: flex-start;
        form{
          width:100%;
          flex-direction: row;
          justify-content: center;
          flex-wrap: wrap;
          .elem {
            margin-bottom: 0px;
          }
          .inputContainer{
            width:40%;
          }
        }
        .emailContainer{
          height:30px;
          width:40%;
          margin-bottom:0px;
          margin-top: 30px;
          margin-left:20px;
          margin-right:20px;
          position: relative;
          display: inline-block;
          input:disabled{
            margin-top:12px;
            border: none;
            outline:none;
            background-color: transparent;
            border-bottom: 3px solid #aaa;
            width: 100%;
          }
          span{
            visibility: hidden;
            position: absolute;
            top:30px;
            left:0px;
            z-index: 1;
            background-color: #333;
            color:#ddd;
            border-radius: 3px;
            padding:5px 10px;
            transition: 0.2s ease-out;
            opacity: 0.8;
          }
        }
        .emailContainer:hover{
          span{
            visibility: visible;

          }
        }

      }
      .icons{
        display: flex;
        width:100%;
        justify-content: flex-end;
        svg{
          cursor:pointer;
          width:24px;
          height:24px;
          margin:10px;
          fill: $dark-text;
        }
        .red{
          fill: $red;
        }
        .green{
          fill:green;
          width:36px;
          height:36px;
          margin:5px;
        }
        .submitBtns{
          background-color: transparent;
          border: none;
          padding: 0px 0px;
        }

      }
      .propicContainer{
        display: flex;

        #pp{
          height:200px;
          width:200px;
          border-radius:100%;
          background-size: cover;
          margin-top:10px;
          box-shadow: 10px 10px 30px #D8B15F;
          flex-shrink: 0;
        }

      }
      .editPic{
        display: flex;
        width:100%;
        justify-content: flex-start;
        align-items: center;
        position:relative;
        #pp{
          margin:0px 0px 10px 30px;
          width:100px;
          height:100px;
        }
        span{
          svg {
            fill: $light-blue;
            position: absolute;
            left:100px;
            bottom:0px;
            width:36px;
            height:36px;
            z-index: 999;
            cursor:pointer;
          }
        }

      }
      .title{
        display:flex;
        flex-direction:column;
        align-items:center;
        margin: 20px;
        width:60%;
        padding:10px;
        border-bottom: 2px solid #EFE994;
        h4,h3{
          color: $dark-text;
          padding:5px;
          margin:0;
      }
    }
        ul{
          padding:0px;
          list-style: none;
        li{
          font-weight: bold;
          color: #7E7A40;
          padding:10px;
          display:flex;
          justify-content: center;
          span{
            color: $dark-text;
            font-weight: normal;
            padding-left: 10px;
          }
          svg{
            width:24px;
            margin:0;
            fill: #7E7A40;
          }
        }
        }
    }

}

@media only screen and (max-width: 900px){
  .animated{
    width:100%;
  }
}
