@import "../index.scss";

.status-modal{
    .animated.slideInUp{
        width: 26% !important;
        left: 37%;
        right: 37%;
    }
    .modal{
        text-align: center;
        padding: 10px;
        .close-div{
            display: flex;
            flex-direction: row-reverse;
            .close-icon{
                font-size: 20px;
                cursor: pointer;
            }
        }
        header{
            flex-direction: row-reverse;
            justify-content: center !important;
            padding: 0px 20px 10px 20px !important;
            border: 0 !important;
            
        }
        .status-icon{
            text-align: center;
            margin-right: 10px; 
            .material-icons.md-75{
                font-size: 75px;
                color: #5294E2;
            }
            .failed-import{
                color: #B21414 !important;
            }
        }
        .content{
            padding: 0 5px;
            .questions-status{
                .questions-success{
                    display: flex;
                    align-items: center;
                    // justify-content: center;
                    margin: 5px;
                    color: #1FB214;
                }
                .questions-failed{
                    display: flex;
                    align-items: center;
                    // justify-content: center;
                    margin: 5px;
                    color: #B21414;
                }
            }
            .download-tip{
                border-top: 2px dotted #666666;
                font-size: 14px;
                color: #333333;
                margin-top: 13px;
                margin-bottom: 3px;
                text-align: left;
                padding: 10px;
            }
            .download-btn{
                display: flex;
                align-items: center;
                justify-content: center;
                text-decoration: none;
                width: 35%;
                margin: 20px auto;
                margin-top: 0;
                height: 40px;
                border: none;
                color: #fff;
                background-image: linear-gradient(to right, #252b33 0%, #0071b3 50%, #59b3d5 100%);
                font-weight: bold;
                cursor: pointer;
            }
            .errors-tip{
                font-size: 13px;
                color: #444444;
                margin-top: 13px;
                margin-bottom: 3px;
                text-align: left;
                padding: 10px;
            }
        }  
    }

}