@import "../index.scss";
.page-mask {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
}

.btn {
  background: linear-gradient(45deg, #2e3ea7 40%, #4356d5);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  box-shadow: 10px 10px 30px #aaa;
  position: fixed;
  bottom: 50px;
  right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: #fff;
  font-weight: bolder;
  cursor: pointer;
  transition: 0.15s;
  transition-timing-function: ease-out;
  svg {
    fill: #fff;
  }
}
.btn:hover {
  box-shadow: 20px 20px 30px #aaa;
  width: 52px;
  height: 52px;
}

.questionOptions {
  display: flex;
  flex-direction: column;
}
.optionsBtnAdd {
  background: linear-gradient(45deg, #2e3ea7 40%, #4356d5);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  box-shadow: 10px 10px 30px #aaa;
  position: fixed;
  bottom: 120px;
  right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: #fff;
  font-weight: bolder;
  cursor: pointer;
  transition: 0.15s;
  transition-timing-function: ease-out;
  svg {
    fill: #fff;
  }
  animation: 1s forwards 1 e;
}
.optionsBtnImport {
  background: linear-gradient(45deg, #2e3ea7 40%, #4356d5);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  box-shadow: 10px 10px 30px #aaa;
  position: fixed;
  bottom: 180px;
  right: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: #fff;
  font-weight: bolder;
  cursor: pointer;
  transition: 0.15s;
  transition-timing-function: ease-out;
  svg {
    fill: #fff;
  }
  animation: 1s forwards 1 f;
}
.addQuestionLabel {
  position: fixed;
  bottom: 131px;
  right: 115px;

  background: linear-gradient(45deg, #2e3ea7 40%, #4356d5);
  box-shadow: 10px 10px 30px #aaa;
  color: white;
  padding: 6px 8px;
  animation: 1s forwards 1 e;
}
.importQuestionsLabel {
  position: fixed;
  bottom: 190px;
  right: 115px;
  background: linear-gradient(45deg, #2e3ea7 40%, #4356d5);
  box-shadow: 10px 10px 30px #aaa;
  color: white;
  padding: 6px 8px;
  animation: 1s forwards 1 f;
}



.importSVG {
  position: fixed;
  left: 17px;
  width: 20px;
  height: 20px;
}

.edit_mode{
  h1{
    text-decoration: none;
  }
  background-color: $light-blue;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 20px;
  button{
    padding: 10px;
    border-radius: 20px;
    background-color:#4356d5 ;
    border-color: transparent;
    outline: none;
    color:white;
    margin: 10px;
    cursor: pointer;
    width: 70px;
  }
}
@keyframes e {
  100% {
    transform: translateY(-20px);
  }
}
@keyframes f {
  100% {
    transform: translateY(-30px);
  }
}

