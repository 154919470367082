@import "./breakpoints.scss";
@import "./index.scss";

.applications-page {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 10% 80% 10%;
  margin: 40px 0px;
  span {
    grid-column-start: 2;
    grid-column-end: 3;
    margin: 20px 0px;
    font-weight: bold;
    color: #333;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    justify-content: space-between;
    align-items: center;.col{
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      span{
        width: 80%;
        input{
          width: 100%;
        }
      }

        input{
         margin: 0;
         border:none;
         border-bottom: 3px solid $eSpace-light-blue;
         width:80%;
         padding: 10px;
         margin:10px 0px;
         background-color: transparent;
        }
    }
    .toggleSwitch {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      p{
        font-size: 14px;
      }
      .switch{
        position: relative;
        display: inline-block;
        width:80px;
        height:24px;
        input{
          opacity: 0;
          width:0;
          height:0;
        }
        .slider{
          position: absolute;
          cursor: pointer;
          margin: 0;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          height:100%;
          background-color: #ddd;
          transition: 0.4s ease-out;
          border-radius: 34px;
          border-top-left-radius: 34px;
          border-top-right-radius: 34px;
          border-bottom-right-radius: 34px;
          border-bottom-left-radius: 34px;
        }
        .slider:before{
          content: "";
          position: absolute;
          height:16px;
          width:16px;
          border-radius: 100%;
          left:4px;
          bottom:4px;
          background-color:#fff;
          transition: 0.4s ease-out;
        }
        input:checked + .slider {
          background-color: $eSpace-light-blue;
        }
        input:checked + .slider:before{
          transform: translateX(40px);
        }
      }
    }


    }

  .application {
    grid-column-start: 2;
    grid-column-end: 3;
    background-color: #fff;
    min-height: 100px;
    margin: 10px 0px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    transition: 0.1s ease-out;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    padding: 1.2em;
    @media only screen and (max-width: 1070px){
      flex-direction: column;
    }
    @include media('<tablet'){
      flex-direction: column;
    }
    section {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      max-width: 20%;
      @include media('<tablet'){
        max-width: 100%;
      }
      button {
        border: none;
        outline: none;
        background-color: $main-blue;
        color: #fff;
        font-weight: bold;
        padding: 5px 10px;
        cursor: pointer;
        box-shadow: 0px 2px 10px #ddd;
        border-radius: 5px;
      }
      button:hover {
        box-shadow: 0px 5px 15px #ddd;
      }
      button[disabled],
      button[disabled]:hover {
        background-color: #ddd;
        box-shadow: 0px 2px 10px #ddd;
      }
      span {
        margin: 10px 0px;
      }
      .label {
        color: #aaa;
        font-size: 1rem;
        @include media('<tablet'){
          margin-bottom: 5px;
        }
      }
      .value {
        color: $text-dark;
        font-weight: bold;
        font-size: 1rem;
        @include media('<tablet'){
          margin-top: 0px;
          margin-bottom: 15px;
        }
      }
      .placeholder-label{
        height: 12px;
        width: 70%;
        animation: pulse 1s infinite ease-in-out;
        -webkit-animation: pulse 1s infinite ease-in-out;
      }
      .placeholder-value{
        height: 12px;
        width: 90%;
        animation: pulse 1s infinite ease-in-out;
        -webkit-animation: pulse 1s infinite ease-in-out;
      }
      @keyframes pulse{
        0% {
          background-color: rgba(165,165,165,.1);
        }
        50% {
          background-color: rgba(165,165,165,.3);
        }
        100% {
          background-color: rgba(165,165,165,.1);
        }
      }
      @-webkit-keyframes pulse{
        0% {
          background-color: rgba(165,165,165,.1);
        }
        50% {
          background-color: rgba(165,165,165,.3);
        }
        100% {
          background-color: rgba(165,165,165,.1);
        }
      }
      .submit-label{
        @include media('<tablet'){
          display: none;
        }
      }
      .submit-btn{
        padding: 0.4rem;
        margin-top: 0.7rem;
      }
    }
    .placeholder-section{
      min-width: 20%;
    }
    .submit-section{
      @include media('<tablet'){
        align-self: center;
      }
    }
    .remaining-time-under-submit-button{
      display: flex;
      align-items: center;
    }
  }
  .application:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  }
  .application-exam{
    flex-wrap: wrap;
    padding: 0.4rem 1rem 1rem 0.875rem !important;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
    .ribbon-container{
      flex: 0 0 100%;
      .ribbon{
        background-color: #5294E2!important;
        border-color: #3A54D3!important;
        color: #fff!important;
        left: calc(-1rem - 1.2em);
        position: relative;
        min-width: -webkit-max-content;
        min-width: -moz-max-content;
        min-width: max-content;
        border-radius: 0 .28571429rem .28571429rem 0 !important;
        border-color: rgba(0,0,0,.15);
        font-size: .84rem;
        display: inline-block;
        line-height: 1;
        vertical-align: baseline;
        margin: 0 .14285714em;
        background-image: none;
        padding: .5833em .833em;
        color: rgba(0,0,0,.6);
        text-transform: none;
        font-weight: 700;
        border: 0 solid transparent;
        border-radius: .28571429rem;
        text-decoration: none;
        box-sizing: inherit;
        cursor: default;
      }

      .ribbon:hover{
        background-color: #576cd4!important;
        border-color: #427abd !important;
      }
      
      .ribbon::after{
        position: absolute;
        content: '';
        top: 100%;
        left: 0;
        background-color: transparent!important;
        border-style: solid;
        border-width: 0 1.2em 1.2em 0;
        border-color: transparent;
        border-right-color: inherit;
        width: 0;
        height: 0;
        box-sizing: inherit;
      }
    }
  }
  .red {
    border-left: 10px solid $eSpace-red;
  }
  .yellow {
    border-left: 10px solid $yellow;
  }
}
.tab-button {
  border-bottom: $hover-border-size solid $main-blue;
  height: 50px;
  width: 150px;
  background-color: white;
  border-top: none;
  border-right: none;
  border-left: none;
  cursor: pointer;
  font-size: 14px;
  color: black;
  outline: none;
}

.tab-button:hover {
  cursor: pointer;
  font-weight: bold;
  color: $yellow;
  text-decoration: none;
  font-size: 14px;
}

.button-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  width: 80%;
  flex-direction: row;
  margin-top: 30px;
}
.applications-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.view_btn{
  text-decoration: none;
  background-color: #3a54d3;
  padding: 10px 10px 10px 10px;
  height: 15px;
  border-radius: 20px;
  margin-top: 1.2rem;
  cursor: pointer;
  color: white;


}
.pages{
  margin-top: 0px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg{
    padding: 5px;
    fill: #333;
    height:18px;
    width:18px;
    cursor: pointer;
  }
  svg:hover{
    fill: $main-blue;
  }
  #rotate{
     transform: rotate(180deg);
  }
  span{
    min-width: 20px;
    min-height: 20px;
    padding:5px;
    margin:5px;
    cursor:pointer;
    background-color: #eee;
    color: #333;
    border-radius:100%;
    transition: 0.1s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  span:hover{
    color: $main-blue;
  }
  .active{
    background-color: $main-blue;
    color: #fff;
  }
  .active:hover{
    color: #fff;
  }
}
.fixed-width-date{
  width:130px
}
.fixed-width{
  width: 130%;
}
.fixed-width-duration{
  width: 100%
}
.exam-filters{
  display: flex;
  .filter-item{
    margin-left: 2.5rem;
  }
  .radio {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
  }
  .radio .radio-choices label{
    background-color: #f9f9f9;
  }
  .radio .radio-choices label:hover{
    background-color: $eSpace-light-blue;
  }
  .radio .radio-choices input:checked{
    +label{
      color: #fff;
      background-color: $eSpace-light-blue;
    }
  }
}
