@import "./index.scss";

.nav-container {
  position: static;
//  left:0;
//  right:0;
//  top:0;
  height: 8vh;
  width:100vw;
  margin:0;
  background-color: $main-blue;
  padding:0;
  box-shadow: 0px 10px 15px $light-blue;
  display: flex;
  align-items: center;
//  z-index: 99999;
  .logo {
    height:28px;
    padding: 10px 50px;
  }
  .links-container {
    display: flex;
    height: 100%;
    flex-direction: row;
    a{
      text-decoration: none;
      align-self: stretch;
      border-bottom: $hover-border-size solid $main-blue;
      border-top: $hover-border-size solid $main-blue;
      display: flex;
      justify-content: center;
      align-items: center;

    .item{
      color: $light-blue;
      width: 120px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      svg {
          fill: $light-blue;
          width: 20px;
          height: 25px;
        }
        span{
          font-size: 14px;
          color: $light-blue;

        }

      }
    }
    .active{
      background-color: $hover;
      border-bottom: $hover-border-size solid $yellow;
      border-top: $hover-border-size solid $hover;
      .item{
        .item-content{
          font-weight: bold;
          color: $yellow;
          font-size: 14px;
          color: $yellow;
          text-decoration: none;
        }
          svg {
            fill: $yellow;
            stroke: $yellow;
            stroke-width: 1;
          }
      }
      }
    }
    a:hover{
      cursor: pointer;
      font-weight: bold;
      color: $yellow;
      text-decoration: none;
      .item{
        svg {
            fill: $yellow;
            stroke: $yellow;
            stroke-width: 1;
          }
          .item-content{
            font-size: 14px;
            color: $yellow;
          }
      }

      }
  }
  .profile-container {
    position: absolute;
    right: 30px;
    color: $light-blue;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-around;
    height:60px;
    }
    .profile {
      display: flex;
      a{
        text-decoration: none;
        color: $light-blue;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        margin-left: 15px;
        svg{
          fill: #fff;
          width: 30px;
          padding: 20px 10px;

      }
      svg:hover, .active{
        fill: $yellow;
        stroke: $yellow;
        stroke-width: 1;
        cursor: pointer;
      }
      }
      #user-name {
        padding-right: 20px;
      }
    #user-name:hover, .active{
      font-weight: bold;
      color: $yellow;
      cursor: pointer;
    }
    #user-pic {
      width: 40px;
      height: 40px;
      border-radius: 30px;
      margin-right:10px;
      background-color: $hover;
      svg {
        fill: #fff;
        margin: 5px 0px 0px 11px;
        width: 25px;
      }
      img {
        height: 100%;
        width:100%;
        border-radius: 100%;
      }
    }
  }


@media screen and (max-width: 800px){
  .nav-container {
    .logo{
      padding: 10px;
      height: 18px;
      cursor:pointer;
    }
    .profile-container {
      right: 10px;
      }
      .profile {
        #user-pic {
          margin: 0px 0px 5px 0px;
        }
        #user-name {
          display: none;
        }
      }
      .actions {
        margin-left: 10px;
      }
      .item{
        max-width: 20px;
        .item-content {
          display: none
        }
        svg {
          min-width: 30px;
          min-height: 30px;
          padding: 0px 0px 0px 10px;
        }
    }
    }
}
