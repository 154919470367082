@import "./index.scss";
.content {
//  display: flex;
//  flex-direction: column;
}
body {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//  flex-direction: row;
  font-family: "Roboto", sans-serif;
  #logo {
    z-index: 1000;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  .sidePic {
    background-image: url("../images/sidePic.png");
    background-size: cover;
    width: 40%;
    height: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .welcome {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    h1 {
      color: #fff;
      letter-spacing: 10px;
      margin-bottom: 0;
    }
    p {
      padding: 10px;
      margin: 0;
      color: #a8c6e8;
      font-size: 18px;
    }
  }
}

@media only screen and (max-width: 900px) {
  body {
     flex-direction: column;
     justify-content: flex-start;
     align-items: flex-start;
    .content {
      flex-direction: column;
      .root-form{
        background-color: #fafafa;
        width:100%;
        height:90%;
        form {
          width:100%;
          .inputContainer{
            width:60%;
          }
          .submitBtn{
            width:60%;
          }
          .checkbox{
            margin: 20px 0px 20px 0px;
            label{
              margin: 0px 20px 0px 0px;
            }
          }
        }
        button{

          margin:0px;
        }
      }
    }

    .sidePic {
      height:50px;
      width:100%;
      z-index: 20;
      .welcome{
        display:none;
      }
    }
  }
}
